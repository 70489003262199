<template>
  <div>
    <b-table-custom
      :table-data="dataObjectList"
      :table-columns="columns"
      :is-data-loading="isLoadingTableData"
    >
      <template #button_event >
        <b-dropdown class="menu-button"
                    variant="primary"
                    size="sm"
        >
          <template #button-content>
            <feather-icon icon="SettingsIcon" class="mr-1"/>
            <span>Action Menu</span>
          </template>
          <b-dropdown-item v-for="(menuItem, index) in actionMenuList"
                           :key="index"
                           @click="openModal(menuItem['name'])"
          >
            <i :class="`${menuItem['icon']} text-${menuItem['color']}`"/>
            <span>{{ menuItem['label'] }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #menu="{item}">
        <template v-for="(menuItem, index) in menuButtonAction">
          <b-dropdown-item
              v-if="menuItem['visible'] === undefined || menuItem['visible'](item)"
              @click="openModal(menuItem['name'], item)"
              :key="index"
          >
            <i :class="`${menuItem['icon']} text-${menuItem['color']}`"/>
            <span>{{ menuItem['label'] }}</span>
          </b-dropdown-item>
        </template>
      </template>

      <template #cell(name)="{data}">
        <strong class="text-primary">{{ data['item']['name'] }}</strong>
      </template>
      <template #cell(plan_type)="{data}">
        <span>{{ data['item']['plan_type']['name'] }}</span>
      </template>
      <template #cell(bandwidth)="{data}">
        <span>{{ data['item']['bandwidth'] }} GB</span>
      </template>
      <template #cell(buying_price)="{data}">
        <span class="text-info">{{ currencyFormat(data['item']['buying_price']) }}</span>
      </template>
      <template #cell(selling_price)="{data}">
        <span class="text-success">{{ currencyFormat(data['item']['selling_price']) }}</span>
      </template>
      <template #cell(actual_buying_price)="{data}">
        <span>{{ currencyFormatUSD(data['item']['actual_buying_price']) }}</span>
      </template>
    </b-table-custom>

    <custom-modal id="proxy-modal"
                  ref="proxy-modal"
                  :title="currentModal.title"
                  :variant="currentModal.variant"
                  :ok-title="currentModal.okTitle"
                  :cancel-title="currentModal.cancelTitle"
                  :is-manual-close="currentModal.isManualClose"
                  @ok="currentModal.okAction"
    >
      <b-card-text v-if="modalName === 'modal-add-new'">
        <custom-input v-model="newProxyPlan['name']"
                      label="Name"
                      placeholder="Name"
        />
        <custom-select v-model="newProxyPlan['plan_type_id']"
                       :options="planTypeList"
                       display-field="name"
                       reduce="id"
                       label="Plan type"
                       placeholder="Select plan type"/>
        <custom-input v-model="newProxyPlan['bandwidth']"
                      label="Bandwidth"
                      placeholder="Bandwidth"
                      type="number"
        />
        <div class="row">
          <money-box class="col-sm-6" v-model="newProxyPlan['selling_price']"
                     label="Giá bán ra"
                     color="info"
          />
          <custom-input class="col-sm-6" v-model="newProxyPlan['actual_buying_price']"
                        label="Giá thực tế ($)"/>
        </div>
        <custom-input v-model="newProxyPlan['location']"
                      label="Location"
                      placeholder="Location"
        />
        <b-form-group>
          <label>Description</label>
          <b-form-textarea v-model="newProxyPlan['description']"
                           placeholder="Description"
          />
        </b-form-group>
        <b-form-group>
          <label>Note</label>
          <b-form-textarea v-model="newProxyPlan['note']"
                           placeholder="Note"
          />
        </b-form-group>
        <b-form-group>
          <switch-button v-model="newProxyPlan['is_active']"
                         true-label="Active"
                         false-label="Inactive"
                         true-variant="success"
          />
        </b-form-group>
      </b-card-text>
      <b-card-text v-if="modalName === 'modal-update'">
        <custom-input v-model="dataObjectSelectedClone['name']"
                      label="Name"
                      placeholder="Name"
        />
        <custom-select v-model="dataObjectSelectedClone['plan_type']['id']"
                       :options="planTypeList"
                       display-field="name"
                       reduce="id"
                       label="Plan type"
                       placeholder="Select plan type"/>
        <custom-input v-model="dataObjectSelectedClone['bandwidth']"
                      label="Bandwidth"
                      placeholder="Bandwidth"
                      type="number"
        />
        <b-form-group>
          <div class="row">
            <money-box class="col-sm-6" v-model="dataObjectSelectedClone['selling_price']"
                       label="Giá bán ra"
                       color="info"
            />
            <custom-input class="col-sm-6" v-model="dataObjectSelectedClone['actual_buying_price']"
                          label="Giá thực tế ($)"/>
          </div>
        </b-form-group>
        <custom-input v-model="dataObjectSelectedClone['location']"
                      label="Location"
                      placeholder="Location"
        />
        <b-form-group>
          <label>Description</label>
          <b-form-textarea v-model="dataObjectSelectedClone['description']"
                           placeholder="Description"
          />
        </b-form-group>
        <b-form-group>
          <label>Note</label>
          <b-form-textarea v-model="dataObjectSelectedClone['note']"
                           placeholder="Note"
          />
        </b-form-group>
        <b-form-group>
          <switch-button v-model="dataObjectSelectedClone['is_active']"
                         true-label="Active"
                         false-label="Inactive"
                         true-variant="success"
          />
        </b-form-group>
      </b-card-text>
      <b-card-text v-if="modalName === 'modal-active' || modalName === 'modal-deactive'">
        <p>Are you sure you want to {{ modalName === 'modal-active' ? 'active' : 'inactive' }} this plan: <strong>{{ this.dataObjectSelected['name'] }}</strong></p>
      </b-card-text>
      <b-card-text v-if="modalName === 'modal-delete'">
        <p>Are you sure you want to delete this plan: <strong>{{ this.dataObjectSelected['name'] }}</strong></p>
        <switch-button v-model="dataObjectSelectedClone['is_completely_delete']"
                       true-label="Xoá hoàn toàn"
                       false-label="Xoá logic"
                       true-variant="danger"
        />
      </b-card-text>
    </custom-modal>
  </div>
</template>

<script>
import BTableCustom from "@/views/components/table/BTableCustom.vue";
import InputHelper from "@/mixins/utils/inputHelper";
import DataRequester from "@/mixins/utils/dataRequester";
import DataObjectList from "@/mixins/utils/dataObjectList";

import CustomModal from "@/views/components/CustomModal.vue";
import CustomInput from "@/views/components/form/CustomInput.vue";
import MoneyBox from "@/views/components/MoneyBox.vue";
import SwitchButton from "@/views/components/form/SwitchButton.vue";
import CustomSelect from "@/views/components/form/CustomSelect.vue";

import planService from "@/mixins/utils/axios/plan.service";
import proxyService from "@/mixins/utils/axios/proxy.service";

export default {
  name: 'ProxyPlan',
  mixins: [InputHelper, DataRequester, DataObjectList],
  components: {
    MoneyBox,
    BTableCustom,
    CustomModal,
    CustomInput,
    SwitchButton,
    CustomSelect
  },
  computed: {
    currentModal () {
      let self = this
      switch(this.modalName) {
        case 'modal-add-new':
          return {
            title: 'Thêm mới gói',
            okTitle: 'Thêm mới',
            cancelTitle: 'Hủy',
            variant: 'success',
            isManualClose: true,
            okAction: () => {
              self.insertNewProxyPlan()
            }
          }
        case 'modal-update':
          return {
            title: 'Cập nhật gói',
            okTitle: 'Cập nhật',
            cancelTitle: 'Hủy',
            variant: 'info',
            isManualClose: true,
            okAction: () => {
              self.updateProxyPlan()
            }
          }
        case 'modal-active':
          return {
            title: 'Kích hoạt gói',
            okTitle: 'Kích hoạt',
            cancelTitle: 'Hủy',
            variant: 'success',
            isManualClose: true,
            okAction: () => {
              self.activeProxyPlan()
            }
          }
        case 'modal-deactive':
          return {
            title: 'Ngưng kích hoạt gói',
            okTitle: 'Ngưng kích hoạt',
            cancelTitle: 'Hủy',
            variant: 'danger',
            isManualClose: true,
            okAction: () => {
              self.inactiveProxyPlan()
            }
          }
        case 'modal-delete':
          return {
            title: 'Xóa gói',
            okTitle: 'Xóa',
            cancelTitle: 'Hủy',
            variant: 'danger',
            isManualClose: true,
            okAction: () => {
              self.deleteProxyPlan()
            }
          }
        default:
          return {
            title: 'Modal title',
            okTitle: 'OK',
            cancelTitle: 'Cancel',
            variant: 'primary',
            isManualClose: false,
            okAction: () => {}
          }
      }
    }
  },
  data () {
    return {
      isLoadingTableData: false,
      columns: [
        {
          key: 'name',
          label: 'Tên gói',
        },
        {
          key: 'plan_type',
          label: 'Loại gói'
        },
        {
          key: 'bandwidth',
          label: 'Băng thông'
        },
        {
          key: 'buying_price',
          label: 'Mua vào'
        },
        {
          key: 'selling_price',
          label: 'Bán ra'
        },
        {
          key: 'actual_buying_price',
          label: 'Thực tế'
        },
        {
          key: 'location',
          label: 'Vị trí',
          sortable: true
        },
        {
          key: 'description',
          label: 'Mô tả'
        },
        {
          key: 'note',
          label: 'Ghi chú'
        },
        {
          key: 'is_active',
        },
        {
          key: 'menu'
        }
      ],
      actionMenuList: [
        {
          name: 'modal-add-new',
          label: 'Thêm mới gói',
          icon: 'fas fa-plus',
          color: 'success'
        }
      ],
      menuButtonAction: [
        {
          name: 'modal-update',
          label: 'Cập nhật',
          icon: 'fas fa-edit',
          color: 'info'
        },
        {
          name: 'modal-active',
          label: 'Kích hoạt',
          icon: 'fas fa-check',
          color: 'success',
          visible: (item) => {
            return !item['is_active']
          }
        },
        {
          name: 'modal-deactive',
          label: 'Ngưng kích hoạt',
          icon: 'fas fa-times',
          color: 'danger',
          visible: (item) => {
            return item['is_active']
          }
        },
        {
          name: 'modal-delete',
          label: 'Xóa',
          icon: 'fas fa-trash',
          color: 'danger'
        }
      ],
      modalName: '',
      newProxyPlan: {},
      planTypeList: []
    }
  },
  created() {
    this.getPlanList()
    this.getPlanTypeList()
  },
  methods: {
    openModal(modalName, data) {
      this.modalName = modalName;
      this.dataObjectSelected = data
      this.dataObjectSelectedClone = {...data}

      this.$nextTick(() => {
        this.$refs['proxy-modal'].show()
      })
    },
    closeModal() {
      this.$refs['proxy-modal'].hide()
    },

    getPlanTypeList() {
      let self = this
      this.runApi(planService.listPlanType(), function (response) {
        self.planTypeList = response.data
      })
    },
    getPlanList() {
      let self = this
      this.isLoadingTableData = true
      this.runApi(proxyService.getProxyPlanList(), function (response) {
        self.dataObjectList = response.data
      }, undefined, function () {
        self.isLoadingTableData = false
      })
    },
    insertNewProxyPlan() {
      let self = this
      this.runApi(proxyService.insertProxyPlan(this.newProxyPlan), function () {
        self.getPlanList()
      }, undefined, function () {
        self.closeModal()
      }, true)
    },
    activeProxyPlan() {
      let self = this
      this.runApi(proxyService.activeProxyPlan({id: self.dataObjectSelected['id']}), function () {
        self.setItemProperty(self.dataObjectSelected, 'is_active', true)
      }, undefined, function () {
        self.closeModal()
      }, true)
    },
    inactiveProxyPlan() {
      let self = this
      this.runApi(proxyService.inactiveProxyPlan({id: self.dataObjectSelected['id']}), function () {
        self.setItemProperty(self.dataObjectSelected, 'is_active', false)
      }, undefined, function () {
        self.closeModal()
      }, true)
    },
    updateProxyPlan() {
      let self = this
      this.runApi(proxyService.updateProxyPlan({
        ...this.dataObjectSelectedClone,
        plan_type_id: this.dataObjectSelectedClone['plan_type']['id']
      }), function () {
        self.getPlanList()
      }, undefined, function () {
        self.closeModal()
      }, true)
    },
    deleteProxyPlan() {
      let self = this
      this.runApi(proxyService.deleteProxyPlan({
        id: self.dataObjectSelectedClone['id'],
        is_completely_delete: self.dataObjectSelectedClone['is_completely_delete']
      }), function () {
        self.dataObjectList = self.dataObjectList.filter(item => item['id'] !== self.dataObjectSelected['id'])
      }, undefined, function () {
        self.closeModal()
      }, true)
    }
  }
}
</script>
